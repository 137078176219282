import WizardHat from '../../icons/WizardHat';
import { ColorSet } from '../../../models/color-set.model';
import CardDealerIcon from '../../icons/CardDealerIcon';
export interface PlayerPayload {
  id: string;
  name: string;
  color: ColorSet;
  order?: number;
  className?: string;
  size?: 'xs' | 'sm' | 'md';
  isCardDealer?: boolean;
  onClick?: any;
}

const Player = (props: PlayerPayload) => {
  const { className, color, name, size = 'md', isCardDealer = false, onClick = ()=>{} } = props;
  const iconClasses = (): string => {
    switch (size) {
      case 'xs':
        return 'flex-shrink-0 w-6 h-6 mr-1';
      case 'sm':
        return 'flex-shrink-0 w-8 h-8 mr-2';
      default:
        return 'flex-shrink-0 w-12 h-12 mr-2';
    }
  };

  const nameClasses = (): string => {
    switch (size) {
      case 'xs':
        return 'font-semibold text-sm';
      case 'sm':
        return 'font-semibold text-lg';
      default:
        return 'font-semibold text-xl';
    }
  };
  return (
    <div className={`flex items-center ${className}`} onClick={onClick}>
      <WizardHat
        className={iconClasses()}
        mainColor={color.mainColor}
        secondColor={color.secondColor}
      />
      <div className={nameClasses()}>{name}</div>
      {isCardDealer ? (
        <CardDealerIcon className="w-8 mx-2 text-secondary flex-shrink-0" />
      ) : (
        ''
      )}
    </div>
  );
};

export default Player;
