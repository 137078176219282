export interface RoundButtonPayload {
  children: any;
  onClick: Function;
}

const RoundButton = (props: RoundButtonPayload) => {
  return (
    <button
      className="font-semibold h-10 w-10 p-1 m-2"
      onClick={() => {
        props.onClick();
      }}
    >
      {props.children}
    </button>
  );
};

export default RoundButton;
