export interface CardDealerIconPayload {
  className?: string;
  color?: string;
}

const CardDealerIcon = (props: CardDealerIconPayload) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      viewBox="0 0 24.116 17.409"
    >
      <g data-name="Card Dealer" transform="translate(-2 -5.438)">
        <path
          d="M7.662,8.039a.628.628,0,0,1-.628-.628V4.522H6.783A1.512,1.512,0,0,1,5.275,3.014h-.5A2.013,2.013,0,0,0,6.531,5.009v5.416a.628.628,0,0,1-1.256,0V6.531h-.5v4.9a.628.628,0,0,1-1.256,0v-4.9h-.5v4.145a.628.628,0,1,1-1.256,0V6.029h-.5v3.14A.628.628,0,1,1,0,9.169V4.145a4.145,4.145,0,0,1,8.29,0V7.411A.628.628,0,0,1,7.662,8.039Z"
          transform="translate(2 22.846) rotate(-90)"
          fill={props.color || 'currentColor'}
        />
        <g transform="translate(8.029 5.438)">
          <g transform="translate(0 0)">
            <g
              data-name="Rectangle 16"
              transform="translate(10.852 0) rotate(90)"
              fill="#fff"
              stroke={props.color || 'currentColor'}
              strokeWidth="1"
            >
              <rect width="7.235" height="10.852" rx="1" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="6.235"
                height="9.852"
                rx="0.5"
                fill="none"
              />
            </g>
            <text
              transform="translate(1.206 6.029) rotate(-90)"
              fontSize="2"
              fontFamily="Arial-BoldMT, Arial"
              fontWeight="700"
            >
              <tspan x="-0.722" y="2">
                A
              </tspan>
            </text>
            <text
              data-name="A"
              transform="translate(9.646 1.206) rotate(90)"
              fontSize="2"
              fontFamily="Arial-BoldMT, Arial"
              fontWeight="700"
            >
              <tspan x="-0.722" y="2">
                A
              </tspan>
            </text>
          </g>
          <g data-name="Card" transform="translate(3.617 2.412)">
            <g
              data-name="Rectangle 16"
              transform="translate(10.852 0) rotate(90)"
              fill="#fff"
              stroke={props.color || 'currentColor'}
              strokeWidth="1"
            >
              <rect width="7.235" height="10.852" rx="1" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="6.235"
                height="9.852"
                rx="0.5"
                fill="none"
              />
            </g>
            <text
              data-name="2"
              transform="translate(1.206 6.029) rotate(-90)"
              fontSize="2"
              fontFamily="Arial-BoldMT, Arial"
              fontWeight="700"
            >
              <tspan x="-0.556" y="2">
                2
              </tspan>
            </text>
            <text
              data-name="2"
              transform="translate(9.646 1.206) rotate(90)"
              fontSize="2"
              fontFamily="Arial-BoldMT, Arial"
              fontWeight="700"
            >
              <tspan x="-0.556" y="2">
                2
              </tspan>
            </text>
          </g>
          <g data-name="Card" transform="translate(7.235 4.823)">
            <g
              data-name="Rectangle 16"
              transform="translate(10.852 0) rotate(90)"
              fill="#fff"
              stroke={props.color || 'currentColor'}
              strokeWidth="1"
            >
              <rect width="7.235" height="10.852" rx="1" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="6.235"
                height="9.852"
                rx="0.5"
                fill="none"
              />
            </g>
            <text
              data-name="3"
              transform="translate(1.206 6.029) rotate(-90)"
              fontSize="2"
              fontFamily="Arial-BoldMT, Arial"
              fontWeight="700"
            >
              <tspan x="-0.556" y="2">
                3
              </tspan>
            </text>
            <text
              data-name="3"
              transform="translate(9.646 1.206) rotate(90)"
              fontSize="2"
              fontFamily="Arial-BoldMT, Arial"
              fontWeight="700"
            >
              <tspan x="-0.556" y="2">
                3
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CardDealerIcon;
