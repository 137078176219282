
import { Player as IPlayer } from '../../../models/player.model';
import { Round } from '../../../models/round.model';

import { LocalStorageHelper } from '../../../helpers/local-storage';
import ResultsGrid from '../../../components/ui/results-grid/ResultsGrid.styled';
import WizardHat from '../../../components/icons/WizardHat';

const RoundPoints = () => {
  const currentRound: Round = LocalStorageHelper.getCurrentRound();

  const roundList: Round[] = LocalStorageHelper.getRoundList();
  const players: IPlayer[] = LocalStorageHelper.getPlayerList();
  const orderList: any = {};

  for (const player of players) {
    orderList[player.id] = player.order;
  }

  return (
    <>
      <h2>Rounds Points</h2>

      <ResultsGrid
        numberOfPlayers={players.length}
        numberOfRounds={currentRound.id - 1}
      >
        <div className="flex items-end order-1 w-full text-center font-semibold p-1 bg-secondary h-full">
          Round
        </div>
        {players.map((player, index) => (
          <div
            className="flex flex-col items-center border-l border-primary-white w-full p-1 font-semibold h-full justify-end text-center bg-secondary"
            style={{ order: index + 2 }}
            key={player.id}
          >
            <div>
              <WizardHat
                mainColor={player.color.mainColor}
                secondColor={player.color.secondColor}
                className="w-8"
              />
            </div>
            {player.name}
          </div>
        ))}

        {roundList.map((round) => (
          <>
            <div
              className={`w-full h-full flex justify-center items-center border-t ${
                round.id % 2 === 0
                  ? 'border-secondary bg-primary-white'
                  : 'border-secondary'
              }`}
              style={{ order: round.id * (1 + players.length) + 1 }}
            >
              {round.id}
            </div>

            {round.tricks.map((trick) => (
              <div
                className={`w-full h-full flex justify-center items-center border-l border-t  " ${
                  round.id % 2 === 0
                    ? 'border-secondary bg-primary-white'
                    : 'border-secondary'
                }`}
                style={{
                  order:
                    round.id * (players.length + 1) +
                    orderList[trick.player.id] +
                    1,
                }}
              >
                {trick.result}
              </div>
            ))}
          </>
        ))}
      </ResultsGrid>
      <button
        className="mx-auto my-4"
        onClick={() => {
          window.scroll(0, 0);
        }}
      >
        Go to top
      </button>
    </>
  );
};

export default RoundPoints;
