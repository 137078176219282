import { Player } from './player.model';

export class GameStatus {
  player: Player;
  position: number;
  totalPoints: number;

  constructor(player: Player) {
    this.player = player;
    this.position = 1;
    this.totalPoints = 0;
  }
}
