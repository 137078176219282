import styles from './ColorSelector.module.css';

import WizardHat from '../../icons/WizardHat';
import { ColorSet } from '../../../models/color-set.model';
import { gameColors } from '../../helpers/colors';
import { KeyboardEvent } from 'react';

export interface ColorSelectorPayload {
  value?: ColorSet;
  onChangeValue?: (color: ColorSet) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLDivElement> | undefined) => any;
}

const wizardHatWrapperBaseClasses =
  'flex w-12 max-w-12 h-12 m-1 p-1 rounded-full';
const wizardHatWrapperActiveClass = styles['selected-shadow'];

const ColorSelector = (props: ColorSelectorPayload) => {
  const { onKeyUp = (event: any) => {}, onChangeValue, value } = props;

  return (
    <div className="p-1 w-full flex flex-wrap justify-between mt-4">
      <label className="w-full font-medium text-accent">Select a Color</label>
      {gameColors.map((colorItem) => {
        let wizardHatWrapperClasses = wizardHatWrapperBaseClasses;

        if (value?.key === colorItem.key) {
          wizardHatWrapperClasses = `${wizardHatWrapperBaseClasses} ${wizardHatWrapperActiveClass}`;
        }
        return (
          <div
            tabIndex={0}
            onKeyUp={(event) => onKeyUp(event)}
            key={colorItem.key}
            className={wizardHatWrapperClasses}
            onClick={() => {
              onChangeValue !== undefined && onChangeValue(colorItem);
            }}
          >
            <WizardHat
              mainColor={colorItem.mainColor}
              secondColor={colorItem.secondColor}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ColorSelector;
