import { Player } from './player.model';

export class Trick {
  player: Player;
  bet?: number;
  done?: number;

  constructor(player: Player) {
    this.player = player;
  }

  get result(): number {
    if (this.done === undefined || this.bet === undefined) {
      return 0;
    }

    if (this.bet === this.done) {
      return 20 + 10 * this.bet;
    }

    return -10 * Math.abs(this.bet - this.done);
  }
}
