import {
  CURRENT_ROUND_KEY,
  PLAYER_LIST_KEY,
  ROUND_LIST_KEY,
  TOTAL_CARDS,
  TOTAL_ROUNDS_KEY,
} from './constants';
import { Player } from './../models/player.model';
import { Round } from './../models/round.model';
import { GameStatus } from './../models/game-status.model';

export class LocalStorageHelper {
  static getPlayerList(): Player[] {
    const players: Player[] = JSON.parse(
      localStorage.getItem(PLAYER_LIST_KEY) || '[]'
    );
    return players;
  }

  static setPlayerList(players: Player[]): void {
    localStorage.setItem(PLAYER_LIST_KEY, JSON.stringify(players));

    localStorage.setItem(
      TOTAL_ROUNDS_KEY,
      (TOTAL_CARDS / players.length).toString()
    );

    // Remove data that is based on the players list
    localStorage.removeItem(CURRENT_ROUND_KEY);
    localStorage.removeItem(ROUND_LIST_KEY);
  }

  static getCurrentRound(): Round {
    const players: Player[] = this.getPlayerList();
    const currentRound =
      Round.fromString(localStorage.getItem(CURRENT_ROUND_KEY)) ||
      new Round(1, players);
    return currentRound;
  }

  static setCurrentRound(currentRound: Round): void {
    localStorage.setItem(CURRENT_ROUND_KEY, JSON.stringify(currentRound));
  }

  static getTotalRounds(): number {
    const players: Player[] = this.getPlayerList();
    const totalRounds: number = +(
      localStorage.getItem(TOTAL_ROUNDS_KEY) || TOTAL_CARDS / players.length
    );
    return totalRounds;
  }

  static getRoundList(): Round[] {
    const storedRounds = JSON.parse(
      localStorage.getItem(ROUND_LIST_KEY) || '[]'
    );
    if (!storedRounds || storedRounds.length === 0) {
      return [];
    }

    const roundList: Round[] = [];

    for (const round of storedRounds) {
      const parsedRound = Round.fromString(JSON.stringify(round));
      if (parsedRound) {
        roundList.push(parsedRound);
      }
    }

    return roundList;
  }

  static archiveCurrentRound(): void {
    const currentRound = this.getCurrentRound();

    const savedRounds: Round[] = JSON.parse(
      localStorage.getItem(ROUND_LIST_KEY) || '[]'
    );

    if (currentRound) {
      savedRounds.push(currentRound);
    }

    localStorage.setItem(ROUND_LIST_KEY, JSON.stringify(savedRounds));
  }

  static createNewRound(currentRound: Round): void {
    const newRound = new Round(
      currentRound.id + 1,
      currentRound.tricks.map((trick) => trick.player)
    );

    newRound.status = this.getGameStatus();

    localStorage.setItem(CURRENT_ROUND_KEY, JSON.stringify(newRound));
  }

  static getGameStatus(): GameStatus[] {
    const players = this.getPlayerList();
    const status = players.map((player) => new GameStatus(player));

    const roundList: Round[] = LocalStorageHelper.getRoundList();

    for (const round of roundList) {
      for (const trick of round.tricks) {
        const result = status.find(
          (item) => item.player.id === trick.player.id
        );
        if (result) {
          result.totalPoints += trick.result;
        }
      }
    }

    status.sort((a, b) => {
      if (a.totalPoints > b.totalPoints) {
        return -1;
      }

      if (a.totalPoints < b.totalPoints) {
        return 1;
      }
      return 0;
    });

    return status.map((item, index) => {
      return { ...item, position: index + 1 };
    });
  }
}
