export interface GameColor {
  key: string;
  mainColor: string;
  secondColor: string;
}

export const defaultColor: GameColor = {
  key: 'default',
  mainColor: 'var(--primary-color)',
  secondColor: 'var(--secondary-color)',
};

export const baseColors = [
  { key: 'red', hue: 0, saturation: '100%', lightness: '55%' },
  { key: 'orange', hue: 25, saturation: '100%', lightness: '55%' },
  { key: 'yellow', hue: 45, saturation: '100%', lightness: '55%' },
  { key: 'green', hue: 125, saturation: '100%', lightness: '35%' },
  { key: 'blue', hue: 215, saturation: '100%', lightness: '55%' },
  { key: 'purple', hue: 285, saturation: '100%', lightness: '55%' },
  { key: 'pink', hue: 315, saturation: '100%', lightness: '80%' },
  { key: 'gray', hue: 288, saturation: '0%', lightness: '50%' },
];

export const gameColors = [
  defaultColor,
  ...baseColors.map((color) => {
    return {
      key: color.key,
      mainColor: `hsl(${color.hue}, ${color.saturation}, ${color.lightness}`,
      secondColor: `hsl(${color.hue}, ${color.saturation}, 100%`,
    };
  }),
];

export const getColorByNumber = (number: number): GameColor => {
  const realIndex =
    number < gameColors.length ? number : number % gameColors.length;

  return gameColors[realIndex];
};
