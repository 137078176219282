import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './components/header/Header';
import PlayerDefinition from './pages/player-definition';
import Home from './pages/home';
import SetTricks from './pages/set-tricks/index';
import RoundOverview from './pages/round-overview/index';
import SetResult from './pages/set-result/index';
import NewRound from './pages/new-round/index';
import GameResults from './pages/game-results/index';

const App = () => {
  return (
    <Router basename="/">
      <Header />
      <main className="page-limitations">
        <Switch>
          <Route path="/player-definition">
            <PlayerDefinition />
          </Route>
          <Route path="/set-tricks">
            <SetTricks />
          </Route>
          <Route path="/round-overview">
            <RoundOverview />
          </Route>
          <Route path="/set-result">
            <SetResult />
          </Route>
          <Route path="/new-round">
            <NewRound />
          </Route>
          <Route path="/game-results">
            <GameResults />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </main>
    </Router>
  );
};

export default App;
