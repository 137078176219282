import { useHistory } from 'react-router-dom';

import { Round } from './../../models/round.model';
import ThrophyIcon from './../../components/icons/TrophyIcon';
import CardDealerIcon from './../../components/icons/CardDealerIcon';
import Heading from './../../components/heading/Heading';
import { LocalStorageHelper } from './../../helpers/local-storage';
import PartialResults from '../../components/app/partial-results/PartialResults';
import RoundPoints from 'src/components/app/round-points/RoundPoints';

const RoundOverview = () => {
  const history = useHistory();

  const totalRounds: number = LocalStorageHelper.getTotalRounds();

  const currentRound: Round = LocalStorageHelper.getCurrentRound();

  return (
    <div className="">
      <Heading>Time to play!</Heading>
      <div className="text-lg py-3 text-center">
        Round <strong>{currentRound.id}</strong> of{' '}
        <strong>{totalRounds}</strong>
      </div>
      <div className="flex flex-col max-w-192 mx-auto w-full pb-8">
        {currentRound.tricks.map((trick, index) => {
          return (
            <div
              className="py-1 flex items-center  justify-between"
              key={trick.player.id}
            >
              <div className="flex items-center text-xl font-semibold">
                <div className="relative">
                  <div
                    className="absolute font-bold mt-1 text-accent text-center text-base w-full"
                    style={{ color: trick.player.color.secondColor }}
                  >
                    {
                      currentRound.status.find(
                        (item) => item.player.id === trick.player.id
                      )?.position
                    }
                  </div>
                  <ThrophyIcon
                    color={trick.player.color.mainColor}
                    className="w-10"
                  />
                </div>
                <div className="ml-2">{trick.player.name}</div>
                {index === currentRound.tricks.length - 1 ? (
                  <CardDealerIcon className="w-8 mx-2 text-secondary" />
                ) : (
                  ''
                )}
              </div>
              <div className="text-accent text-lg flex-shrink-0">
                Bet: <strong>{trick.bet}</strong>
              </div>
            </div>
          );
        })}

        <button
          className="mx-auto mt-4"
          onClick={() => {
            history.push('/set-result');
          }}
        >
          Add Results
        </button>
        
        <RoundPoints />
        <PartialResults />
      </div>
    </div>
  );
};

export default RoundOverview;
