import { ReactSortable } from 'react-sortablejs';

import { Player as IPlayer } from '../../../../models/player.model';

import Player from '../../../app/player/Player';
import OrderIcon from '../../../icons/OrderIcon';
import RemoveIcon from '../../../icons/RemoveIcon';
import IconWrapper from './../../../icons/IconWrapper';
export interface OrderablePlayerListPayload {
  players: IPlayer[];
  setPlayers: (players: IPlayer[]) => void;
  removePlayer: (player: IPlayer) => void;
  className?: string;
}

const OrderablePlayerList = (props: OrderablePlayerListPayload) => {
  const updatePlayerList = (newPlayerList: IPlayer[]) => {
    const listWithOrderUpdated = newPlayerList.map((item, index) => {
      return { ...item, order: index };
    });
    props.setPlayers(listWithOrderUpdated);
  };

  return (
    <ReactSortable
      list={props.players}
      setList={updatePlayerList}
      className={props.className}
    >
      {props.players.map((item) => (
        <div key={item.id} className="flex cursor-move items-center">
          <OrderIcon
            className="w-6 flex mr-2 text-middle"
            color={item.color.mainColor}
          />
          <Player
            className="w-full"
            id={item.id}
            name={item.name}
            color={item.color}
          />
          <IconWrapper onClick={() => props.removePlayer(item)}>
            <RemoveIcon
              color={item.color.mainColor}
              className="flex text-middle flex-shrink-0"
            />
          </IconWrapper>
        </div>
      ))}
    </ReactSortable>
  );
};

export default OrderablePlayerList;
