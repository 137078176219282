import { useHistory } from 'react-router-dom';

import { Player as IPlayer } from '../../models/player.model';
import { Round } from '../../models/round.model';
import Heading from '../../components/heading/Heading';

import { LocalStorageHelper } from './../../helpers/local-storage';
import ThrophyIcon from '../../components/icons/TrophyIcon';
import RoundPoints from 'src/components/app/round-points/RoundPoints';

const GameResults = () => {
  const history = useHistory();

  const currentRound: Round = LocalStorageHelper.getCurrentRound();

  
  const players: IPlayer[] = LocalStorageHelper.getPlayerList();
  const orderList: any = {};

  for (const player of players) {
    orderList[player.id] = player.order;
  }

  return (
    <div className="flex flex-col">
      <Heading>Game Results</Heading>

      {currentRound.status.map((item) => (
        <div className="flex flex-col" key={item.player.id}>
          <div className="flex items-center text-xl font-semibold w-full mb-2">
            <div className="relative">
              <div
                className="absolute font-bold mt-1 text-accent text-center text-base w-full"
                style={{ color: item.player.color.secondColor }}
              >
                {item.position}
              </div>
              <ThrophyIcon
                color={item.player.color.mainColor}
                className="w-10"
              />
            </div>
            <div className="ml-2">{item.player.name}</div>

            <div className="ml-auto">{item.totalPoints}</div>
          </div>
        </div>
      ))}
      <button
        className="mx-auto my-4"
        onClick={() => {
          history.push('/player-definition');
        }}
      >
        Start new Game
      </button>
      
      <RoundPoints />
    </div>
  );
};

export default GameResults;
