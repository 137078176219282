export interface WizardHatPayload {
  className?: string;
  mainColor: string;
  secondColor: string;
}

const WizardHat = (props: WizardHatPayload) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 239.992 164.685"
      className={props.className}
    >
      <g id="Group_1" data-name="Group 1" transform="translate(-53.18 -37.224)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M2929.632,1053.751c31.354-26.743,34.735-84.224,54.1-114.348s23.048,7.377,25.507,9.836-23.97-30.124-15.67,0,19.014,82.287,46.723,104.512c0,0,75.618-16.292,61.478-7.377s-59.652,42.639-118.037,43.034-112.174-39.259-115.5-41.452C2854.707,1039.042,2929.632,1053.751,2929.632,1053.751Z"
          transform="translate(-2811.863 -889.002)"
          fill={props.mainColor}
          stroke={props.mainColor}
          strokeWidth="3"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M3037.154,1063.666s-28.064,4.076-54.072,4.027c-17.834-.033-49.793-4.028-49.793-4.028a81.725,81.725,0,0,0,5.713-6.334,287.788,287.788,0,0,0,44.08,3.491,429.789,429.789,0,0,0,48.124-3.491A76.587,76.587,0,0,0,3037.154,1063.666Z"
          transform="translate(-2812.226 -902.014)"
          fill={props.secondColor}
          stroke={props.secondColor}
          strokeWidth="3"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M2998.7,1102.973l-7.02,7.02,7.02,7.02,7.02-7.02Z"
          transform="translate(-2825.5 -947.593)"
          fill={props.mainColor}
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default WizardHat;
