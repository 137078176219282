export interface EditIconPayload {
  className?: string;
  color?: string;
}

const EditIcon = (props: EditIconPayload) => {
  return (
    <svg
      id="Group_45"
      data-name="Group 45"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.002 18.003"
      fill={props.color || 'currentColor'}
      className={props.className}
    >
      <path
        id="Path_77"
        data-name="Path 77"
        d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z"
        transform="translate(-3 -2.998)"
      />
    </svg>
  );
};

export default EditIcon;
