export interface InputWrapperPayload {
  label: string;
  children: any;
}

const InputWrapper = (props: InputWrapperPayload) => {
  return (
    <div className="p-1 flex flex-col text-primary">
      <label>{props.label}</label>
      {props.children}
    </div>
  );
};

export default InputWrapper;
