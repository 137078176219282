import { useHistory } from 'react-router-dom';

import { Player as IPlayer } from '../../models/player.model';
import Player from '../../components/app/player/Player';
import { Round } from '../../models/round.model';
import Heading from '../../components/heading/Heading';
import CardDealerIcon from '../../components/icons/CardDealerIcon';
import { LocalStorageHelper } from '../../helpers/local-storage';
import PartialResults from '../../components/app/partial-results/PartialResults';
import RoundCounter from 'src/components/app/round-counter';

const NewRound = () => {
  const history = useHistory();
  const currentRound: Round = LocalStorageHelper.getCurrentRound();

  const startingPlayer: IPlayer = currentRound.tricks[0].player;
  const cardDealer: IPlayer =
    currentRound.tricks[currentRound.tricks.length - 1].player;

  return (
    <div className="flex flex-col pb-8">
      <Heading>New Round!</Heading>
      <RoundCounter currentRoundId={currentRound.id} />
      <button
        className="mx-auto mt-4"
        onClick={() => {
          history.push('/set-tricks');
        }}
      >
        Start the Round
      </button>
      <div className="flex flex-col max-w-192 mx-auto mt-4 w-full">
        <div className="shadow-card flex flex-col py-2 px-4 my-3 rounded-lg items-center">
          <h2 className="w-full text-middle mb-4 flex">
            Card Dealer <CardDealerIcon className="w-8 ml-2" />
          </h2>
          <Player
            id={cardDealer.id}
            name={cardDealer.name}
            color={cardDealer.color}
            size="md"
          />
        </div>
        <div className="shadow-card flex flex-col py-2 px-4 my-3 rounded-lg items-center">
          <h2 className="w-full text-middle mb-4">Starting Player</h2>
          <Player
            id={startingPlayer.id}
            name={startingPlayer.name}
            color={startingPlayer.color}
            size="md"
          />
        </div>

        <PartialResults />
      </div>
    </div>
  );
};

export default NewRound;
