export interface OrderIconPayload {
  className?: string;
  color?: string;
}

const OrderIcon = (props: OrderIconPayload) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={props.color || 'currentColor'}
      className={props.className}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 9H4v2h16V9zM4 15h16v-2H4v2z" />
    </svg>
  );
};

export default OrderIcon;
