import { Trick } from './trick.model';
import { Player } from './player.model';
import { GameStatus } from './game-status.model';

export class Round {
  id: number;
  tricks: Trick[];
  status: GameStatus[];

  constructor(id: number, players: Player[]) {
    this.id = id;
    this.tricks = players
      .map((player) => {
        return new Trick({
          ...player,
          order:
            player.order < id ? player.order + players.length : player.order,
        });
      })
      .sort((a, b) => {
        if (a.player.order < b.player.order) {
          return -1;
        }
        if (a.player.order > b.player.order) {
          return 1;
        }

        return 0;
      });
    this.status = players.map((player) => new GameStatus(player));
  }

  static fromString(stringifiedJson: string | null): Round | undefined {
    if (!stringifiedJson) {
      return undefined;
    }

    const parsedData = JSON.parse(stringifiedJson);
    const id = parsedData['id'];
    const tricksJson = parsedData['tricks'];
    const tricks = (tricksJson as Trick[]).map((trick) => {
      const newTrick = new Trick(trick.player);
      newTrick.bet = trick.bet;
      newTrick.done = trick.done;

      return newTrick;
    });
    const status = parsedData['status'];

    return { id, tricks, status };
  }
}
