import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Player as IPlayer } from '../../models/player.model';

import SavePlayer from '../../components/app/player/save-player/SavePlayer';
import OrderablePlayerList from '../../components/app/player/orderable-player-list/OrderablePlayerList';
import { LocalStorageHelper } from './../../helpers/local-storage';

const PlayerDefinition = () => {
  const initialPlayers: IPlayer[] = LocalStorageHelper.getPlayerList();

  const [players, setPlayers] = useState(initialPlayers);
  const [displayNewPlayerForm, setDisplayNewPlayerForm] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setDisplayNewPlayerForm(players.length < 3);
    LocalStorageHelper.setPlayerList(players);
  }, [players]);

  const getNewPlayerOrder = (playerList: IPlayer[]): number => {
    if (playerList.length === 0) {
      return 0;
    }
    const orders = playerList.map((player, index) => player.order || index);
    const lastOrder = Math.max(...orders);
    return lastOrder + 1;
  };

  const addPlayerHandler = () => {
    setDisplayNewPlayerForm(true);
  };

  const removePlayer = (player: IPlayer) => {
    setPlayers(
      players
        .filter((item) => item.id !== player.id)
        .map((item, index) => {
          return { ...item, order: index };
        })
    );
  };

  const startGameHandler = () => {
    history.push('/set-tricks');
  };

  return (
    <div className="mb-4 lg:flex">
      <div className="lg:mx-4 w-full">
        <div className="my-2 py-2 text-2xl text-center font-bold border-b-2 border-secondary">
          Add your Players
        </div>
        {players.length === 0 ? (
          <div className="text-center my-2">No players added so far</div>
        ) : (
          <OrderablePlayerList
            players={players}
            setPlayers={setPlayers}
            removePlayer={removePlayer}
            className="mb-2 max-w-192 mx-auto w-full"
          />
        )}
        {players.length >= 3 && (
          <div className="flex flex-col my-2">
            <div>
              {players.length < 6
                ? 'If you already have all your players'
                : 'You have set all your players (the game only allow up to 6)'}
              , <strong>be sure to sort the players</strong> having the dealer
              as the first player, and the other players following the playing
              order!
            </div>
            <div className="flex justify-between my-2">
              <button
                className="mx-auto bg-secondary text-primary font-semibold"
                onClick={startGameHandler}
              >
                Start the game
              </button>
              {players.length >= 3 && players.length < 6 && (
                <button
                  className="mx-auto font-semibold"
                  onClick={addPlayerHandler}
                >
                  Add a Player
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {displayNewPlayerForm && (
        <SavePlayer
          className="mt-4 lg:w-1/3 flex-shrink-0"
          onSavePlayer={(player) => {
            if (
              players.find(
                (item) =>
                  item.name.toLocaleLowerCase() ===
                    player.name.toLocaleLowerCase() &&
                  item.color === player.color
              ) !== undefined
            ) {
              // TODO: Display a message saying that the user already exists
              return;
            }

            setPlayers((oldPlayerList) => {
              return [
                ...oldPlayerList,
                { ...player, order: getNewPlayerOrder(oldPlayerList) },
              ];
            });
          }}
        />
      )}
    </div>
  );
};

export default PlayerDefinition;
