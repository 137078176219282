import { Round } from '../../../models/round.model';
import { LocalStorageHelper } from '../../../helpers/local-storage';
import ThrophyIcon from '../../../components/icons/TrophyIcon';

const PartialResults = () => {
 
  const currentRound: Round = LocalStorageHelper.getCurrentRound();
  
  return (
    <>
        <h2 className="mt-8">Partial Results</h2>
        {currentRound.status.map((item) => (
          <div className="flex flex-col" key={item.player.id}>
            <div className="flex items-center text-xl font-semibold w-full mb-2">
              <div className="relative">
                <div
                  className="absolute font-bold mt-1 text-accent text-center text-base w-full"
                  style={{ color: item.player.color.secondColor }}
                >
                  {item.position}
                </div>
                <ThrophyIcon
                  color={item.player.color.mainColor}
                  className="w-10"
                />
              </div>
              <div className="ml-2">{item.player.name}</div>

              <div className="ml-auto">{item.totalPoints}</div>
            </div>
          </div>
        ))}
      </>
  );
};

export default PartialResults;
