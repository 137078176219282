import styled from 'styled-components';
export interface IProps {
  area: string;
}

const HeaderGridArea = styled.div<IProps>`
  grid-area: ${(props) => props.area};
`;

export default HeaderGridArea;
