import styled from 'styled-components';

interface IconWrapperProps {
  readonly backgroundColorOnHover?: string;
  readonly borderColorOnFocus?: string;
}

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 100%;
  border: 1px solid transparent;

  &:hover {
    background-color: ${(props) =>
      props.backgroundColorOnHover
        ? props.backgroundColorOnHover
        : 'hsla(var(--primary-color-hue), 25%, 50%, 0.5)'};
  }

  &:focus {
    border: 1px solid
      ${(props) =>
        props.borderColorOnFocus
          ? props.borderColorOnFocus
          : 'hsla(var(--primary-color-hue), 25%, 50%, 0.5)'};
  }
`;

export default IconWrapper;
