import { LocalStorageHelper } from '../../../helpers/local-storage';

export interface RoundCounterPayload {
  currentRoundId: number;
}

const RoundCounter = (props: RoundCounterPayload) => {
  const totalRounds: number = LocalStorageHelper.getTotalRounds();
  return (
    <div className="text-lg py-3 text-center">
      Round <strong>{props.currentRoundId}</strong> of{' '}
      <strong>{totalRounds}</strong>
    </div>
  );
};

export default RoundCounter;
