import styled from 'styled-components';

const HeaderGrid = styled.div`
  grid-template-columns: 4rem 1fr 4rem;
  grid-template-rows: auto auto;
  grid-template-areas:
    'logo-area app-name-area .'
    'menu-area menu-area menu-area ';
`;

export default HeaderGrid;
