import { useState } from 'react';
import { defaultColor } from '../../../../components/helpers/colors';
import { ObjectId } from '../../../../helpers/object-id';
import { ColorSet } from '../../../../models/color-set.model';
import { Player } from '../../../../models/player.model';

import ColorSelector from '../../../ui/color-selector/ColorSelector';
import Input from '../../../ui/input/InputWrapper';

export interface SavePlayerPayload {
  player?: Player;
  onSavePlayer: (player: Player) => void;
  className?: string;
}

export const getNewBasePlayer = () => {
  return { id: ObjectId.mongoObjectId(), name: '', color: defaultColor };
};

const SavePlayer = (props: SavePlayerPayload) => {
  const initialPlayer = props.player ? props.player : getNewBasePlayer();

  const [player, setPlayer] = useState(initialPlayer);

  let playerNameInputRef: HTMLInputElement | null;

  const setPlayerNameInputFocus = () => {
    if (playerNameInputRef !== null) {
      playerNameInputRef.focus();
    }
  };

  const triggerSaveIfEnterKey = (key: string | undefined) => {
    if (key === 'Enter') {
      triggerSavePlayer();
    }
  };

  const triggerSavePlayer = () => {
    props.onSavePlayer({ ...player, order: 0 });
    setPlayer(getNewBasePlayer());
    setPlayerNameInputFocus();
  };

  return (
    <div
      className={`flex flex-col items-center border border-primary p-2 rounded-lg ${props.className}`}
    >
      <Input label="Player Name">
        <input
          ref={(reference) => (playerNameInputRef = reference)}
          type="text"
          onChange={(event: any) => {
            const name = event.target.value;
            setPlayer((oldPlayer) => {
              return { ...oldPlayer, name };
            });
          }}
          onKeyUp={(event) => triggerSaveIfEnterKey(event.key)}
          value={player.name}
          maxLength={30}
        />
      </Input>
      <ColorSelector
        value={player.color}
        onChangeValue={(color: ColorSet) => {
          setPlayer((oldPlayer) => {
            return { ...oldPlayer, color };
          });
        }}
        onKeyUp={(event) => triggerSaveIfEnterKey(event?.key)}
      />
      <button
        className="mt-2 flex flex-col"
        disabled={player.name.trim().length <= 2}
        onClick={triggerSavePlayer}
      >
        Add Player
      </button>
    </div>
  );
};

export default SavePlayer;
