import styled from 'styled-components';
export interface IProps {
  numberOfPlayers: number;
  numberOfRounds: number;
}

const ResultsGrid = styled.div<IProps>`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: auto repeat(${(props) => props.numberOfPlayers}, 1fr);
  grid-template-rows: auto repeat(${(props) => props.numberOfRounds}, 2.25rem);
  border: 1px solid var(--secondary-color);
  margin: 1rem 0;
  overflow: auto;
`;

export default ResultsGrid;
