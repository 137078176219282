import { useHistory } from 'react-router-dom';

import Player from './../../components/app/player/Player';
import { Round } from './../../models/round.model';
import { useState } from 'react';
import { useEffect } from 'react';
import EditIcon from '../../components/icons/EditIcon';
import { defaultColor } from './../../components/helpers/colors';
import { Trick } from './../../models/trick.model';
import Heading from './../../components/heading/Heading';
import { LocalStorageHelper } from './../../helpers/local-storage';
import RoundButton from './../../components/ui/round-button/RoundButton';
import RoundCounter from '../../components/app/round-counter';

const SetTricks = () => {
  const history = useHistory();

  const storedCurrentRound: Round = LocalStorageHelper.getCurrentRound();

  const [currentRound, setCurrentRound] = useState(storedCurrentRound);
  const [activePlayer, setActivePlayer] = useState(
    currentRound.tricks.find((item) => !item.bet && item.bet !== 0)?.player
  );

  useEffect(() => {
    LocalStorageHelper.setCurrentRound(currentRound);
  }, [currentRound]);

  const setBetForActivePlayer = (bet: number) => {
    setCurrentRound((upToDateCurrentRound: Round): Round => {
      const trick = upToDateCurrentRound.tricks.find(
        (trick) => trick.player.id === activePlayer?.id
      );
      if (trick) {
        trick.bet = bet;
      }
      setActivePlayer(
        currentRound.tricks.find((item) => !item.bet && item.bet !== 0)?.player
      );
      return { ...upToDateCurrentRound };
    });
  };

  const getBetControls = (): any => {
    let controls = [];

    for (let possibleBet = 0; possibleBet <= currentRound.id; possibleBet++) {
      controls.push(
        <RoundButton
          key={possibleBet}
          onClick={() => {
            setBetForActivePlayer(possibleBet);
          }}
        >
          {possibleBet}
        </RoundButton>
      );
    }

    return (
      <div className="flex justify-between px-2 w-full flex-wrap">
        {[...controls]}
      </div>
    );
  };

  const resetBet = (trick: Trick) => {
    setCurrentRound((upToDateCurrentRound: Round): Round => {
      trick.bet = undefined;
      setActivePlayer(
        currentRound.tricks.find((item) => !item.bet && item.bet !== 0)?.player
      );
      return { ...upToDateCurrentRound };
    });
  };

  return (
    <div className="flex flex-col pb-8">
      <Heading>Bet Time!</Heading>
      <RoundCounter currentRoundId={currentRound.id} />
      <div className="flex flex-col max-w-192 mx-auto w-full">
        {currentRound.tricks.map((trick, index) => {
          const isActivePlayer = trick.player.id === activePlayer?.id;
          return (
            <div
              className={`my-2 py-1 flex items-center justify-between ${
                isActivePlayer &&
                'flex-col border rounded-lg border-secondary p-2 shadow-card'
              }`}
              key={trick.player.id}
            >
              <Player
                className="mr-4"
                id={trick.player.id}
                name={trick.player.name}
                color={trick.player.color}
                size={isActivePlayer || !activePlayer ? 'md' : 'xs'}
                isCardDealer={index === currentRound.tricks.length - 1}
                onClick={() => {
                  resetBet(trick);
                }}
              />
              {isActivePlayer ? (
                getBetControls()
              ) : trick.bet === undefined ? (
                ''
              ) : (
                <div
                  className="flex text-accent justify-end flex-shrink-0"
                  onClick={() => {
                    resetBet(trick);
                  }}
                >
                  Bet: {trick.bet}
                  <EditIcon
                    color={defaultColor.mainColor}
                    className="ml-4 w-4"
                  />
                </div>
              )}
            </div>
          );
        })}
        {!activePlayer ? (
          <button
            className="mx-auto mt-4"
            onClick={() => {
              history.push('/round-overview');
            }}
          >
            Save Bets
          </button>
        ) : (
          <div className="my-4">
            You need to set a bet for every player before starting to play the
            round! You can always check again the game rules{' '}
            <a href="/game-rules">here</a>.
          </div>
        )}
      </div>
    </div>
  );
};

export default SetTricks;
