export interface HeadingPayload {
  children: any;
}

const Heading = (props: HeadingPayload) => {
  return (
    <h1 className="border-b-2 border-secondary my-0 py-2 text-2xl text-center text-primary">
      {props.children}
    </h1>
  );
};

export default Heading;
