import { Link, useHistory } from 'react-router-dom';

import WizardHat from '../../components/icons/WizardHat';
import { getColorByNumber } from '../../components/helpers/colors';
import SpacedListItem from './SpacedListItem.styled';

const Home = () => {
  const history = useHistory();

  return (
    <div className="home-page text-primary">
      <h1 className="text-center">
        <span className="harrington-font text-5xl">Wizard </span>
        Counter
      </h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to the Wizard Counter App, this App will let you focus on the
          actual game and do all the calculations for you with minimal input.
          The App is free to use, click <Link to="/about">here</Link> if you
          want to know more about it,
        </p>
      </section>
      <section className="pb-4">
        <h2>Let's Start</h2>
        <p>
          here you have a short explanation on how this app works, if you
          already know it you can click{' '}
          <Link to="/player-definition">here</Link> to start the app:
        </p>
        <ul>
          <SpacedListItem className="flex">
            <WizardHat
              className="w-4 flex-shrink-0 mr-2"
              mainColor={getColorByNumber(0).mainColor}
              secondColor={getColorByNumber(0).secondColor}
            />
            In the first step you will add the players and ajust their position
            in the list based in the playing order.
          </SpacedListItem>
          <SpacedListItem className="flex">
            <WizardHat
              className="w-4 flex-shrink-0 mr-2"
              mainColor={getColorByNumber(1).mainColor}
              secondColor={getColorByNumber(1).secondColor}
            />
            The next step you will set the dealer and we will set for you the
            starting player as the player after the dealer.
          </SpacedListItem>
          <SpacedListItem className="flex">
            <WizardHat
              className="w-4 flex-shrink-0 mr-2"
              mainColor={getColorByNumber(2).mainColor}
              secondColor={getColorByNumber(2).secondColor}
            />
            Now that everything is set up you can start the game, we will ask
            each player's prediction in the order you specified before, already
            applying the limits for the round.
          </SpacedListItem>
          <SpacedListItem className="flex">
            <WizardHat
              className="w-4 flex-shrink-0 mr-2"
              mainColor={getColorByNumber(3).mainColor}
              secondColor={getColorByNumber(3).secondColor}
            />
            While you play the round, we show you the current ranking. To finish
            the round you just need to click on the 'Add Round Results' button.
          </SpacedListItem>
          <SpacedListItem className="flex">
            <WizardHat
              className="w-4 flex-shrink-0 mr-2"
              mainColor={getColorByNumber(4).mainColor}
              secondColor={getColorByNumber(4).secondColor}
            />
            Then we will ask you how many tricks each player actually completed.
          </SpacedListItem>
          <SpacedListItem className="flex">
            <WizardHat
              className="w-4 flex-shrink-0 mr-2"
              mainColor={getColorByNumber(5).mainColor}
              secondColor={getColorByNumber(5).secondColor}
            />
            The ranking will be updated and then we're back to defining the
            player's prediction.
          </SpacedListItem>
          <SpacedListItem className="flex">
            <WizardHat
              className="w-4 flex-shrink-0 mr-2"
              mainColor={getColorByNumber(6).mainColor}
              secondColor={getColorByNumber(6).secondColor}
            />
            At the end of the game, small statistics will be displayed besides
            the Ranking.
          </SpacedListItem>
        </ul>
        <p className="py-2">
          No more delaying, click on the button bellow to start the fun!
        </p>
        <button
          className="flex mx-auto my-4 font-semibold"
          onClick={() => {
            history.push('/player-definition');
          }}
        >
          Start
        </button>
      </section>
    </div>
  );
};

export default Home;
