import { useHistory } from 'react-router-dom';

import Player from '../../components/app/player/Player';
import { Round } from '../../models/round.model';
import { useState } from 'react';
import { useEffect } from 'react';
import EditIcon from '../../components/icons/EditIcon';
import { defaultColor } from '../../components/helpers/colors';
import { Trick } from '../../models/trick.model';
import Heading from '../../components/heading/Heading';
import ErrorIcon from '../../components/icons/ErrorIcon';
import { LocalStorageHelper } from '../../helpers/local-storage';
import RoundButton from '../../components/ui/round-button/RoundButton';
import RoundCounter from 'src/components/app/round-counter';

const SetResult = () => {
  const history = useHistory();

  const totalRounds: number = LocalStorageHelper.getTotalRounds();

  const storedCurrentRound: Round = LocalStorageHelper.getCurrentRound();

  const [currentRound, setCurrentRound] = useState(storedCurrentRound);
  const [activePlayer, setActivePlayer] = useState(
    currentRound.tricks.find((item) => !item.done && item.done !== 0)?.player
  );
  const [availableTricks, setAvailableTricks] = useState(currentRound.id);

  const getTotalTricksDone = (round: Round): number => {
    return round.tricks
      .map((item) => item.done || 0)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const [totalTricksDone, setTotalTricksDone] = useState(
    getTotalTricksDone(currentRound)
  );

  useEffect(() => {
    LocalStorageHelper.setCurrentRound(currentRound);
    setTotalTricksDone(getTotalTricksDone(currentRound));
  }, [currentRound]);

  useEffect(() => {
    const maxTricks = currentRound.id;
    const totalTricksSoFar = getTotalTricksDone(currentRound);
    const totalAvailableTricks = maxTricks - totalTricksSoFar;

    if (totalAvailableTricks !== availableTricks) {
      setAvailableTricks(totalAvailableTricks);

      if (totalAvailableTricks === 0) {
        setCurrentRound((upToDateCurrentRound: Round): Round => {
          for (const trick of upToDateCurrentRound.tricks) {
            if (trick.done === undefined) {
              trick.done = 0;
            }
          }
          setActivePlayer(undefined);
          return { ...upToDateCurrentRound };
        });
      }
    }
  }, [currentRound, availableTricks]);

  const setTricksDoneForActivePlayer = (done: number) => {
    setCurrentRound((upToDateCurrentRound: Round): Round => {
      const trick = upToDateCurrentRound.tricks.find(
        (trick) => trick.player.id === activePlayer?.id
      );
      if (trick) {
        trick.done = done;
      }

      setActivePlayer(
        currentRound.tricks.find((item) => !item.done && item.done !== 0)
          ?.player
      );
      return { ...upToDateCurrentRound };
    });
  };

  const getTricksDoneControls = (): any => {
    let controls = [];

    for (
      let possibleTricksDone = 0;
      possibleTricksDone <= availableTricks;
      possibleTricksDone++
    ) {
      controls.push(
        <RoundButton
          key={possibleTricksDone}
          onClick={() => {
            setTricksDoneForActivePlayer(possibleTricksDone);
          }}
        >
          {possibleTricksDone}
        </RoundButton>
      );
    }

    return (
      <div className="flex justify-between px-2 w-full flex-wrap">
        {[...controls]}
      </div>
    );
  };

  const resetTricksDone = (trick: Trick) => {
    setCurrentRound((upToDateCurrentRound: Round): Round => {
      trick.done = undefined;
      setActivePlayer(
        currentRound.tricks.find((item) => !item.done && item.done !== 0)
          ?.player
      );
      return { ...upToDateCurrentRound };
    });
  };

  const saveRound = (): void => {
    LocalStorageHelper.archiveCurrentRound();
    LocalStorageHelper.createNewRound(currentRound);

    if (currentRound.id < totalRounds) {
      history.push('/new-round');
    } else {
      history.push('/game-results');
    }
  };

  return (
    <div className="pb-8">
      <Heading>Round over</Heading>
      <RoundCounter currentRoundId={currentRound.id} />
      {totalTricksDone !== currentRound.id && activePlayer === undefined ? (
        <div className="flex border-2 border-red rounded-lg py-2 pl-2 pr-4 items-center text-lg text-justify ">
          <ErrorIcon className="text-red mr-2 w-8 flex-shrink-0" />
          The total tricks done does not match with the total tricks (
          {currentRound.id}) for this round
        </div>
      ) : (
        ''
      )}
      <div className="flex flex-col max-w-192 mx-auto w-full">
        {currentRound.tricks.map((trick, index) => {
          const isActivePlayer = trick.player.id === activePlayer?.id;
          return (
            <div
              className={`my-2 py-1 flex items-center justify-between ${
                isActivePlayer &&
                'flex-col border rounded-lg border-secondary p-2 shadow-card'
              }`}
              key={trick.player.id}
            >
              <div className="flex items-center justify-between">
                <Player
                  className="mr-4"
                  id={trick.player.id}
                  name={trick.player.name}
                  color={trick.player.color}
                  size={isActivePlayer || !activePlayer ? 'md' : 'xs'}
                  isCardDealer={index === currentRound.tricks.length - 1}
                  onClick={() => {
                    resetTricksDone(trick);
                  }}
                />
                {isActivePlayer ? (
                  <div className="flex flex-col text-center">
                    <div>Done/Bet:</div>
                    <div>
                      <strong className="text-primary">
                        {trick.done ?? '?'}
                      </strong>
                      /{trick.bet}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {isActivePlayer ? (
                getTricksDoneControls()
              ) : (
                <div
                  className="flex text-middle justify-end"
                  onClick={() => {
                    resetTricksDone(trick);
                  }}
                >
                  <strong className="text-primary">{trick.done ?? '?'}</strong>/
                  {trick.bet}
                  {trick.done === undefined ? (
                    ''
                  ) : (
                    <EditIcon
                      color={defaultColor.mainColor}
                      className="ml-4 w-4"
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
        {!activePlayer && totalTricksDone === currentRound.id ? (
          <button
            className="mx-auto mt-4"
            onClick={() => {
              saveRound();
            }}
          >
            Save Results
          </button>
        ) : (
          <div className="my-4">
            You need to set how many tricks each player did before continuing to
            the next round.
          </div>
        )}
      </div>
    </div>
  );
};

export default SetResult;
