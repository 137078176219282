import { Link } from 'react-router-dom';

import WizardHat from '../icons/WizardHat';
import HeaderGrid from './HeaderGrid.styled';
import HeaderGridArea from './HeaderGridArea.styled';

const Header = () => {
  return (
    <header className="bg-primary text-secondary flex w-full py-4">
      <HeaderGrid className="page-limitations grid sm:flex">
        <HeaderGridArea
          area="logo-area"
          className="
          flex flex-shrink-0
          w-16 h-16 p-2
          bg-white rounded-full"
        >
          <WizardHat
            className="w-full"
            mainColor="var(--primary-color)"
            secondColor="var(--secondary-color)"
          />
        </HeaderGridArea>
        <HeaderGridArea
          area="app-name-area"
          className="flex-col justify-center px-4"
        >
          <div
            className="harrington-font text-white text-3xl text-center
            sm:text-left"
          >
            Wizard
          </div>
          <div className="text-lg text-center">Counter</div>
        </HeaderGridArea>

        <HeaderGridArea
          area="menu-area"
          className="
          flex justify-center
          w-full
          mt-2 pt-2
          text-lg
          border-t border-secondary
          sm:ml-auto sm:p-0
          sm:border-t-0
          sm:items-end
          sm:w-min
          sm:text-2xl"
        >
          <ul className="flex">
            <li className="ml-4">
              <Link
                className="no-underline text-secondary
                active:text-middle
                hover:text-white"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="ml-4">
              <Link
                className="no-underline text-secondary
                active:text-middle
                hover:text-white"
                to="/player-definition"
              >
                App
              </Link>
            </li>
          </ul>
        </HeaderGridArea>
      </HeaderGrid>
    </header>
  );
};

export default Header;
